
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutGM5UHk7qsam6f1oSc2Z49RGj4f4aP_rVMkr_45wA6YtokMeta } from "/usr/src/app/pages/about.vue?macro=true";
import { default as account_45typesqFf8iLb1f4wqtAXvq_qaRgSd28tO2pYvX9PkmbZzib8Meta } from "/usr/src/app/pages/account-types.vue?macro=true";
import { default as affiliateq6dco9G9i7MJXjC9dUBEJAjfBdG2w9cKDH6Xt9M0cBkMeta } from "/usr/src/app/pages/affiliate.vue?macro=true";
import { default as appqOuFeTWQ_k965Emc69uIrBxwLUmgNKAfVspRCbb4KzYMeta } from "/usr/src/app/pages/app.vue?macro=true";
import { default as careersErwY4CAec4WjBiQfBfPmvMMBroKsXuR5YlHIoFlrNx4Meta } from "/usr/src/app/pages/careers.vue?macro=true";
import { default as cfd_45asset_45listELR4nAmIxAUNzLRNlTw354RkxusBRD4DtT3ypbxshiMMeta } from "/usr/src/app/pages/cfd-asset-list.vue?macro=true";
import { default as cfd_45tradingL5dsAl2XhJHXZoFmb0zHE15MmFGupfb79X_fJ42uBcMMeta } from "/usr/src/app/pages/cfd-trading.vue?macro=true";
import { default as commodities3XHW5wsdHETjhww7k7ZEnWlWNJvcdYIhqWu3fLdOaBEMeta } from "/usr/src/app/pages/commodities.vue?macro=true";
import { default as contactNc0C5gLGaEbbKpHBM_45rlHhpMO0I0wWybi0ntpQFD50AMeta } from "/usr/src/app/pages/contact.vue?macro=true";
import { default as cookie_45disclosureikAN6iqasCSDyD9tjC60frn9VkemC1tk6ajIMUxy_WcMeta } from "/usr/src/app/pages/cookie-disclosure.vue?macro=true";
import { default as copy_45tradingEe4AwqgFDKHAHJX9aGJxeKCoyPQ_IE23Op3J9J6IlZQMeta } from "/usr/src/app/pages/copy-trading.vue?macro=true";
import { default as cryptoEZEnOg0velhXsmY2NZZFRloE1GQPbCOfS5B_3MsqUaoMeta } from "/usr/src/app/pages/crypto.vue?macro=true";
import { default as _91slug_930pbfjnaK_455Hh4PbE_sEiZSIQHP9BO9Sm2AbBw9C8uk8Meta } from "/usr/src/app/pages/education-authors/[slug].vue?macro=true";
import { default as _91slug_93EIxHovjTdFa1Pec_U_DLASswg0OdbXCO7vWaDr1N5e4Meta } from "/usr/src/app/pages/education-category/[slug].vue?macro=true";
import { default as education_45centerdIpdYUFtsa6tSBMOGWc4mR7GEGJPmGlfEAolq0T6f1sMeta } from "/usr/src/app/pages/education-center.vue?macro=true";
import { default as _91slug_93GtmeEKOiXtEHm6vu8vZ6_45Da3qeaHENgswIXArsfsKx8Meta } from "/usr/src/app/pages/education-tags/[slug].vue?macro=true";
import { default as _91slug_939KaciUoNJt1pl_45_2EQXryzT0IIjDAND8Fzj1c_454_cFQMeta } from "/usr/src/app/pages/education/[slug].vue?macro=true";
import { default as errorRYtEDh6abTnKkyy_7lQSWxVuEZgx2YgUbeteN7X_458swMeta } from "/usr/src/app/pages/error.vue?macro=true";
import { default as faqsHbe0toH7myFGcyuHvW1CFTyhaDPw96C3bCyIR6gEuBIMeta } from "/usr/src/app/pages/faqs.vue?macro=true";
import { default as forexK3lAxv6Pjx9D6EHdMhgXAv3VJc5e3Ap61G4AQp4OITcMeta } from "/usr/src/app/pages/forex.vue?macro=true";
import { default as forgot_45passwordVkcBdmepjn9AvzDP_4512_tLSFG_KAjMNIC5WmM2XdLHYMeta } from "/usr/src/app/pages/forgot-password.vue?macro=true";
import { default as glossaryAvI3k4PBZQU4JyGUu9_uZ1xDYswQSpepuZmX85xcMlEMeta } from "/usr/src/app/pages/glossary.vue?macro=true";
import { default as ib3L_kzcagu0n1TUkFAvJPApb9I5FA53R0f6L_45QoQXQTYMeta } from "/usr/src/app/pages/ib.vue?macro=true";
import { default as indexdP7zTFRl7aZq2Pc08cwkTh9_Xhz5atMbl890jb4bLIEMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as indicesJAMZ_45lvhyC382_GQIDZjG9nVf6WRHzl3ocCp8cC2i1IMeta } from "/usr/src/app/pages/indices.vue?macro=true";
import { default as _91slug_93unOSA95Nwbxo1rof_45jwHzkHVdYBSRy7iElqOfplXE58Meta } from "/usr/src/app/pages/job-post/[slug].vue?macro=true";
import { default as loginWs2S4x_45jM_t2WOuoeODzX_45F9bUBoxDNgn4jXvry9FDkMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as mt5gn50S9bcL7bV_45c98OeJkiPjgrmx0hyEQjSAVpPBtNocMeta } from "/usr/src/app/pages/mt5.vue?macro=true";
import { default as _91slug_93iXkEuTVgrCBitmPJHUIBrYjC_cTuUpXcAE6_45QY6cjPcMeta } from "/usr/src/app/pages/news-category/[slug].vue?macro=true";
import { default as indexDqs8nepmntNwSaebEnxy55snKVctXO1vAfm5gfOPmggMeta } from "/usr/src/app/pages/news-center/index.vue?macro=true";
import { default as _91_91id_93_930sti3ED4Qze0O7_Jpa9_iuvqHxG1hGyj9Dkp7AT3mXwMeta } from "/usr/src/app/pages/news-center/news/[[id]].vue?macro=true";
import { default as _91slug_936AJ8gLT4zpvs_srN1vRJcwDYQtxtODglNsmLmz5jM8IMeta } from "/usr/src/app/pages/news-tags/[slug].vue?macro=true";
import { default as _91slug_93pUEJpaMQzqXOZ4ADcRcFDxqGFNlL_IkaP6x_4Bc_oaEMeta } from "/usr/src/app/pages/news/[slug].vue?macro=true";
import { default as partnersXEbeMgdpLmOX0IKk359oXKxPnOMi7HddqGzXxbj5rBIMeta } from "/usr/src/app/pages/partners.vue?macro=true";
import { default as privacy_45policybjGteDmGonZUJDy4F0qIFBc21SYwk_0ldpwHnr8ILJ8Meta } from "/usr/src/app/pages/privacy-policy.vue?macro=true";
import { default as registermVjICC6ssMl1WP_453_45hCWCcFBcUapUwtxJEvEeNH5zCkMeta } from "/usr/src/app/pages/register.vue?macro=true";
import { default as regulationWj8wAmQIyohOMR_cB4lc6zSNfQZokdnH0f6FelApcboMeta } from "/usr/src/app/pages/regulation.vue?macro=true";
import { default as stocksqjoh01mH9n0c1TAE4tXIOcbxy1Mfqm1VSiunkMQ3_9IMeta } from "/usr/src/app/pages/stocks.vue?macro=true";
import { default as tagsyVDD7ggefDseSDQbPnDGM5uPX7XX2uNaDrtNeWmQPKUMeta } from "/usr/src/app/pages/tags.vue?macro=true";
import { default as trading_45basicskDwYHc6ZT5VOh3OLI25kgaEItUS_EZqUEqo1_mzAW1sMeta } from "/usr/src/app/pages/trading-basics.vue?macro=true";
import { default as trading_45hourst4rXhetbjurYSkMfS_xBPe_45fIJRPT3_AQXzrqPWAnVIMeta } from "/usr/src/app/pages/trading-hours.vue?macro=true";
import { default as trading_45toolsq3GDy1tWCGaAxobvN2MBDvPTT1255Cx8DHLtDDkG0WYMeta } from "/usr/src/app/pages/trading-tools.vue?macro=true";
import { default as webtraderR2sw8MlHG7gKgV8uMqCwE_vziNcyN6KqWdhf02wzAtgMeta } from "/usr/src/app/pages/webtrader.vue?macro=true";
import { default as why_45usBN23p37oPNwHmH7hyTcgSP5l7L1pqvjag89gEL_2FCAMeta } from "/usr/src/app/pages/why-us.vue?macro=true";
export default [
  {
    name: "about___en___default",
    path: "/about",
    component: () => import("/usr/src/app/pages/about.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/usr/src/app/pages/about.vue")
  },
  {
    name: "about___es",
    path: "/es/about",
    component: () => import("/usr/src/app/pages/about.vue")
  },
  {
    name: "about___it",
    path: "/it/about",
    component: () => import("/usr/src/app/pages/about.vue")
  },
  {
    name: "account-types___en___default",
    path: "/account-types",
    component: () => import("/usr/src/app/pages/account-types.vue")
  },
  {
    name: "account-types___en",
    path: "/en/account-types",
    component: () => import("/usr/src/app/pages/account-types.vue")
  },
  {
    name: "account-types___es",
    path: "/es/account-types",
    component: () => import("/usr/src/app/pages/account-types.vue")
  },
  {
    name: "account-types___it",
    path: "/it/account-types",
    component: () => import("/usr/src/app/pages/account-types.vue")
  },
  {
    name: "affiliate___en___default",
    path: "/affiliate",
    component: () => import("/usr/src/app/pages/affiliate.vue")
  },
  {
    name: "affiliate___en",
    path: "/en/affiliate",
    component: () => import("/usr/src/app/pages/affiliate.vue")
  },
  {
    name: "affiliate___es",
    path: "/es/affiliate",
    component: () => import("/usr/src/app/pages/affiliate.vue")
  },
  {
    name: "affiliate___it",
    path: "/it/affiliate",
    component: () => import("/usr/src/app/pages/affiliate.vue")
  },
  {
    name: "app___en___default",
    path: "/app",
    component: () => import("/usr/src/app/pages/app.vue")
  },
  {
    name: "app___en",
    path: "/en/app",
    component: () => import("/usr/src/app/pages/app.vue")
  },
  {
    name: "app___es",
    path: "/es/app",
    component: () => import("/usr/src/app/pages/app.vue")
  },
  {
    name: "app___it",
    path: "/it/app",
    component: () => import("/usr/src/app/pages/app.vue")
  },
  {
    name: "careers___en___default",
    path: "/careers",
    component: () => import("/usr/src/app/pages/careers.vue")
  },
  {
    name: "careers___en",
    path: "/en/careers",
    component: () => import("/usr/src/app/pages/careers.vue")
  },
  {
    name: "careers___es",
    path: "/es/careers",
    component: () => import("/usr/src/app/pages/careers.vue")
  },
  {
    name: "careers___it",
    path: "/it/careers",
    component: () => import("/usr/src/app/pages/careers.vue")
  },
  {
    name: "cfd-asset-list___en___default",
    path: "/cfd-asset-list",
    component: () => import("/usr/src/app/pages/cfd-asset-list.vue")
  },
  {
    name: "cfd-asset-list___en",
    path: "/en/cfd-asset-list",
    component: () => import("/usr/src/app/pages/cfd-asset-list.vue")
  },
  {
    name: "cfd-asset-list___es",
    path: "/es/cfd-asset-list",
    component: () => import("/usr/src/app/pages/cfd-asset-list.vue")
  },
  {
    name: "cfd-asset-list___it",
    path: "/it/cfd-asset-list",
    component: () => import("/usr/src/app/pages/cfd-asset-list.vue")
  },
  {
    name: "cfd-trading___en___default",
    path: "/cfd-trading",
    component: () => import("/usr/src/app/pages/cfd-trading.vue")
  },
  {
    name: "cfd-trading___en",
    path: "/en/cfd-trading",
    component: () => import("/usr/src/app/pages/cfd-trading.vue")
  },
  {
    name: "cfd-trading___es",
    path: "/es/cfd-trading",
    component: () => import("/usr/src/app/pages/cfd-trading.vue")
  },
  {
    name: "cfd-trading___it",
    path: "/it/cfd-trading",
    component: () => import("/usr/src/app/pages/cfd-trading.vue")
  },
  {
    name: "commodities___en___default",
    path: "/commodities",
    component: () => import("/usr/src/app/pages/commodities.vue")
  },
  {
    name: "commodities___en",
    path: "/en/commodities",
    component: () => import("/usr/src/app/pages/commodities.vue")
  },
  {
    name: "commodities___es",
    path: "/es/commodities",
    component: () => import("/usr/src/app/pages/commodities.vue")
  },
  {
    name: "commodities___it",
    path: "/it/commodities",
    component: () => import("/usr/src/app/pages/commodities.vue")
  },
  {
    name: "contact___en___default",
    path: "/contact",
    component: () => import("/usr/src/app/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/usr/src/app/pages/contact.vue")
  },
  {
    name: "contact___es",
    path: "/es/contact",
    component: () => import("/usr/src/app/pages/contact.vue")
  },
  {
    name: "contact___it",
    path: "/it/contact",
    component: () => import("/usr/src/app/pages/contact.vue")
  },
  {
    name: "cookie-disclosure___en___default",
    path: "/cookie-disclosure",
    component: () => import("/usr/src/app/pages/cookie-disclosure.vue")
  },
  {
    name: "cookie-disclosure___en",
    path: "/en/cookie-disclosure",
    component: () => import("/usr/src/app/pages/cookie-disclosure.vue")
  },
  {
    name: "cookie-disclosure___es",
    path: "/es/cookie-disclosure",
    component: () => import("/usr/src/app/pages/cookie-disclosure.vue")
  },
  {
    name: "cookie-disclosure___it",
    path: "/it/cookie-disclosure",
    component: () => import("/usr/src/app/pages/cookie-disclosure.vue")
  },
  {
    name: "copy-trading___en___default",
    path: "/copy-trading",
    component: () => import("/usr/src/app/pages/copy-trading.vue")
  },
  {
    name: "copy-trading___en",
    path: "/en/copy-trading",
    component: () => import("/usr/src/app/pages/copy-trading.vue")
  },
  {
    name: "copy-trading___es",
    path: "/es/copy-trading",
    component: () => import("/usr/src/app/pages/copy-trading.vue")
  },
  {
    name: "copy-trading___it",
    path: "/it/copy-trading",
    component: () => import("/usr/src/app/pages/copy-trading.vue")
  },
  {
    name: "crypto___en___default",
    path: "/crypto",
    component: () => import("/usr/src/app/pages/crypto.vue")
  },
  {
    name: "crypto___en",
    path: "/en/crypto",
    component: () => import("/usr/src/app/pages/crypto.vue")
  },
  {
    name: "crypto___es",
    path: "/es/crypto",
    component: () => import("/usr/src/app/pages/crypto.vue")
  },
  {
    name: "crypto___it",
    path: "/it/crypto",
    component: () => import("/usr/src/app/pages/crypto.vue")
  },
  {
    name: "education-authors-slug___en___default",
    path: "/education-authors/:slug()",
    component: () => import("/usr/src/app/pages/education-authors/[slug].vue")
  },
  {
    name: "education-authors-slug___en",
    path: "/en/education-authors/:slug()",
    component: () => import("/usr/src/app/pages/education-authors/[slug].vue")
  },
  {
    name: "education-authors-slug___es",
    path: "/es/education-authors/:slug()",
    component: () => import("/usr/src/app/pages/education-authors/[slug].vue")
  },
  {
    name: "education-authors-slug___it",
    path: "/it/education-authors/:slug()",
    component: () => import("/usr/src/app/pages/education-authors/[slug].vue")
  },
  {
    name: "education-category-slug___en___default",
    path: "/education-category/:slug()",
    component: () => import("/usr/src/app/pages/education-category/[slug].vue")
  },
  {
    name: "education-category-slug___en",
    path: "/en/education-category/:slug()",
    component: () => import("/usr/src/app/pages/education-category/[slug].vue")
  },
  {
    name: "education-category-slug___es",
    path: "/es/education-category/:slug()",
    component: () => import("/usr/src/app/pages/education-category/[slug].vue")
  },
  {
    name: "education-category-slug___it",
    path: "/it/education-category/:slug()",
    component: () => import("/usr/src/app/pages/education-category/[slug].vue")
  },
  {
    name: "education-center___en___default",
    path: "/education-center",
    component: () => import("/usr/src/app/pages/education-center.vue")
  },
  {
    name: "education-center___en",
    path: "/en/education-center",
    component: () => import("/usr/src/app/pages/education-center.vue")
  },
  {
    name: "education-center___es",
    path: "/es/education-center",
    component: () => import("/usr/src/app/pages/education-center.vue")
  },
  {
    name: "education-center___it",
    path: "/it/education-center",
    component: () => import("/usr/src/app/pages/education-center.vue")
  },
  {
    name: "education-tags-slug___en___default",
    path: "/education-tags/:slug()",
    component: () => import("/usr/src/app/pages/education-tags/[slug].vue")
  },
  {
    name: "education-tags-slug___en",
    path: "/en/education-tags/:slug()",
    component: () => import("/usr/src/app/pages/education-tags/[slug].vue")
  },
  {
    name: "education-tags-slug___es",
    path: "/es/education-tags/:slug()",
    component: () => import("/usr/src/app/pages/education-tags/[slug].vue")
  },
  {
    name: "education-tags-slug___it",
    path: "/it/education-tags/:slug()",
    component: () => import("/usr/src/app/pages/education-tags/[slug].vue")
  },
  {
    name: "education-slug___en___default",
    path: "/education/:slug()",
    component: () => import("/usr/src/app/pages/education/[slug].vue")
  },
  {
    name: "education-slug___en",
    path: "/en/education/:slug()",
    component: () => import("/usr/src/app/pages/education/[slug].vue")
  },
  {
    name: "education-slug___es",
    path: "/es/education/:slug()",
    component: () => import("/usr/src/app/pages/education/[slug].vue")
  },
  {
    name: "education-slug___it",
    path: "/it/education/:slug()",
    component: () => import("/usr/src/app/pages/education/[slug].vue")
  },
  {
    name: "error___en___default",
    path: "/error",
    component: () => import("/usr/src/app/pages/error.vue")
  },
  {
    name: "error___en",
    path: "/en/error",
    component: () => import("/usr/src/app/pages/error.vue")
  },
  {
    name: "error___es",
    path: "/es/error",
    component: () => import("/usr/src/app/pages/error.vue")
  },
  {
    name: "error___it",
    path: "/it/error",
    component: () => import("/usr/src/app/pages/error.vue")
  },
  {
    name: "faqs___en___default",
    path: "/faqs",
    component: () => import("/usr/src/app/pages/faqs.vue")
  },
  {
    name: "faqs___en",
    path: "/en/faqs",
    component: () => import("/usr/src/app/pages/faqs.vue")
  },
  {
    name: "faqs___es",
    path: "/es/faqs",
    component: () => import("/usr/src/app/pages/faqs.vue")
  },
  {
    name: "faqs___it",
    path: "/it/faqs",
    component: () => import("/usr/src/app/pages/faqs.vue")
  },
  {
    name: "forex___en___default",
    path: "/forex",
    component: () => import("/usr/src/app/pages/forex.vue")
  },
  {
    name: "forex___en",
    path: "/en/forex",
    component: () => import("/usr/src/app/pages/forex.vue")
  },
  {
    name: "forex___es",
    path: "/es/forex",
    component: () => import("/usr/src/app/pages/forex.vue")
  },
  {
    name: "forex___it",
    path: "/it/forex",
    component: () => import("/usr/src/app/pages/forex.vue")
  },
  {
    name: "forgot-password___en___default",
    path: "/forgot-password",
    component: () => import("/usr/src/app/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___en",
    path: "/en/forgot-password",
    component: () => import("/usr/src/app/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___es",
    path: "/es/forgot-password",
    component: () => import("/usr/src/app/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___it",
    path: "/it/forgot-password",
    component: () => import("/usr/src/app/pages/forgot-password.vue")
  },
  {
    name: "glossary___en___default",
    path: "/glossary",
    component: () => import("/usr/src/app/pages/glossary.vue")
  },
  {
    name: "glossary___en",
    path: "/en/glossary",
    component: () => import("/usr/src/app/pages/glossary.vue")
  },
  {
    name: "glossary___es",
    path: "/es/glossary",
    component: () => import("/usr/src/app/pages/glossary.vue")
  },
  {
    name: "glossary___it",
    path: "/it/glossary",
    component: () => import("/usr/src/app/pages/glossary.vue")
  },
  {
    name: "ib___en___default",
    path: "/ib",
    component: () => import("/usr/src/app/pages/ib.vue")
  },
  {
    name: "ib___en",
    path: "/en/ib",
    component: () => import("/usr/src/app/pages/ib.vue")
  },
  {
    name: "ib___es",
    path: "/es/ib",
    component: () => import("/usr/src/app/pages/ib.vue")
  },
  {
    name: "ib___it",
    path: "/it/ib",
    component: () => import("/usr/src/app/pages/ib.vue")
  },
  {
    name: "index___en___default",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "indices___en___default",
    path: "/indices",
    component: () => import("/usr/src/app/pages/indices.vue")
  },
  {
    name: "indices___en",
    path: "/en/indices",
    component: () => import("/usr/src/app/pages/indices.vue")
  },
  {
    name: "indices___es",
    path: "/es/indices",
    component: () => import("/usr/src/app/pages/indices.vue")
  },
  {
    name: "indices___it",
    path: "/it/indices",
    component: () => import("/usr/src/app/pages/indices.vue")
  },
  {
    name: "job-post-slug___en___default",
    path: "/job-post/:slug()",
    component: () => import("/usr/src/app/pages/job-post/[slug].vue")
  },
  {
    name: "job-post-slug___en",
    path: "/en/job-post/:slug()",
    component: () => import("/usr/src/app/pages/job-post/[slug].vue")
  },
  {
    name: "job-post-slug___es",
    path: "/es/job-post/:slug()",
    component: () => import("/usr/src/app/pages/job-post/[slug].vue")
  },
  {
    name: "job-post-slug___it",
    path: "/it/job-post/:slug()",
    component: () => import("/usr/src/app/pages/job-post/[slug].vue")
  },
  {
    name: "login___en___default",
    path: "/login",
    component: () => import("/usr/src/app/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/usr/src/app/pages/login.vue")
  },
  {
    name: "login___es",
    path: "/es/login",
    component: () => import("/usr/src/app/pages/login.vue")
  },
  {
    name: "login___it",
    path: "/it/login",
    component: () => import("/usr/src/app/pages/login.vue")
  },
  {
    name: "mt5___en___default",
    path: "/mt5",
    component: () => import("/usr/src/app/pages/mt5.vue")
  },
  {
    name: "mt5___en",
    path: "/en/mt5",
    component: () => import("/usr/src/app/pages/mt5.vue")
  },
  {
    name: "mt5___es",
    path: "/es/mt5",
    component: () => import("/usr/src/app/pages/mt5.vue")
  },
  {
    name: "mt5___it",
    path: "/it/mt5",
    component: () => import("/usr/src/app/pages/mt5.vue")
  },
  {
    name: "news-category-slug___en___default",
    path: "/news-category/:slug()",
    component: () => import("/usr/src/app/pages/news-category/[slug].vue")
  },
  {
    name: "news-category-slug___en",
    path: "/en/news-category/:slug()",
    component: () => import("/usr/src/app/pages/news-category/[slug].vue")
  },
  {
    name: "news-category-slug___es",
    path: "/es/news-category/:slug()",
    component: () => import("/usr/src/app/pages/news-category/[slug].vue")
  },
  {
    name: "news-category-slug___it",
    path: "/it/news-category/:slug()",
    component: () => import("/usr/src/app/pages/news-category/[slug].vue")
  },
  {
    name: "news-center___en___default",
    path: "/news-center",
    component: () => import("/usr/src/app/pages/news-center/index.vue")
  },
  {
    name: "news-center___en",
    path: "/en/news-center",
    component: () => import("/usr/src/app/pages/news-center/index.vue")
  },
  {
    name: "news-center___es",
    path: "/es/news-center",
    component: () => import("/usr/src/app/pages/news-center/index.vue")
  },
  {
    name: "news-center___it",
    path: "/it/news-center",
    component: () => import("/usr/src/app/pages/news-center/index.vue")
  },
  {
    name: "news-center-news-id___en___default",
    path: "/news-center/news/:id?",
    component: () => import("/usr/src/app/pages/news-center/news/[[id]].vue")
  },
  {
    name: "news-center-news-id___en",
    path: "/en/news-center/news/:id?",
    component: () => import("/usr/src/app/pages/news-center/news/[[id]].vue")
  },
  {
    name: "news-center-news-id___es",
    path: "/es/news-center/news/:id?",
    component: () => import("/usr/src/app/pages/news-center/news/[[id]].vue")
  },
  {
    name: "news-center-news-id___it",
    path: "/it/news-center/news/:id?",
    component: () => import("/usr/src/app/pages/news-center/news/[[id]].vue")
  },
  {
    name: "news-tags-slug___en___default",
    path: "/news-tags/:slug()",
    component: () => import("/usr/src/app/pages/news-tags/[slug].vue")
  },
  {
    name: "news-tags-slug___en",
    path: "/en/news-tags/:slug()",
    component: () => import("/usr/src/app/pages/news-tags/[slug].vue")
  },
  {
    name: "news-tags-slug___es",
    path: "/es/news-tags/:slug()",
    component: () => import("/usr/src/app/pages/news-tags/[slug].vue")
  },
  {
    name: "news-tags-slug___it",
    path: "/it/news-tags/:slug()",
    component: () => import("/usr/src/app/pages/news-tags/[slug].vue")
  },
  {
    name: "news-slug___en___default",
    path: "/news/:slug()",
    component: () => import("/usr/src/app/pages/news/[slug].vue")
  },
  {
    name: "news-slug___en",
    path: "/en/news/:slug()",
    component: () => import("/usr/src/app/pages/news/[slug].vue")
  },
  {
    name: "news-slug___es",
    path: "/es/news/:slug()",
    component: () => import("/usr/src/app/pages/news/[slug].vue")
  },
  {
    name: "news-slug___it",
    path: "/it/news/:slug()",
    component: () => import("/usr/src/app/pages/news/[slug].vue")
  },
  {
    name: "partners___en___default",
    path: "/partners",
    component: () => import("/usr/src/app/pages/partners.vue")
  },
  {
    name: "partners___en",
    path: "/en/partners",
    component: () => import("/usr/src/app/pages/partners.vue")
  },
  {
    name: "partners___es",
    path: "/es/partners",
    component: () => import("/usr/src/app/pages/partners.vue")
  },
  {
    name: "partners___it",
    path: "/it/partners",
    component: () => import("/usr/src/app/pages/partners.vue")
  },
  {
    name: "privacy-policy___en___default",
    path: "/privacy-policy",
    component: () => import("/usr/src/app/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/usr/src/app/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___es",
    path: "/es/privacy-policy",
    component: () => import("/usr/src/app/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___it",
    path: "/it/privacy-policy",
    component: () => import("/usr/src/app/pages/privacy-policy.vue")
  },
  {
    name: "register___en___default",
    path: "/register",
    component: () => import("/usr/src/app/pages/register.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    component: () => import("/usr/src/app/pages/register.vue")
  },
  {
    name: "register___es",
    path: "/es/register",
    component: () => import("/usr/src/app/pages/register.vue")
  },
  {
    name: "register___it",
    path: "/it/register",
    component: () => import("/usr/src/app/pages/register.vue")
  },
  {
    name: "regulation___en___default",
    path: "/regulation",
    component: () => import("/usr/src/app/pages/regulation.vue")
  },
  {
    name: "regulation___en",
    path: "/en/regulation",
    component: () => import("/usr/src/app/pages/regulation.vue")
  },
  {
    name: "regulation___es",
    path: "/es/regulation",
    component: () => import("/usr/src/app/pages/regulation.vue")
  },
  {
    name: "regulation___it",
    path: "/it/regulation",
    component: () => import("/usr/src/app/pages/regulation.vue")
  },
  {
    name: "stocks___en___default",
    path: "/stocks",
    component: () => import("/usr/src/app/pages/stocks.vue")
  },
  {
    name: "stocks___en",
    path: "/en/stocks",
    component: () => import("/usr/src/app/pages/stocks.vue")
  },
  {
    name: "stocks___es",
    path: "/es/stocks",
    component: () => import("/usr/src/app/pages/stocks.vue")
  },
  {
    name: "stocks___it",
    path: "/it/stocks",
    component: () => import("/usr/src/app/pages/stocks.vue")
  },
  {
    name: "tags___en___default",
    path: "/tags",
    component: () => import("/usr/src/app/pages/tags.vue")
  },
  {
    name: "tags___en",
    path: "/en/tags",
    component: () => import("/usr/src/app/pages/tags.vue")
  },
  {
    name: "tags___es",
    path: "/es/tags",
    component: () => import("/usr/src/app/pages/tags.vue")
  },
  {
    name: "tags___it",
    path: "/it/tags",
    component: () => import("/usr/src/app/pages/tags.vue")
  },
  {
    name: "trading-basics___en___default",
    path: "/trading-basics",
    component: () => import("/usr/src/app/pages/trading-basics.vue")
  },
  {
    name: "trading-basics___en",
    path: "/en/trading-basics",
    component: () => import("/usr/src/app/pages/trading-basics.vue")
  },
  {
    name: "trading-basics___es",
    path: "/es/trading-basics",
    component: () => import("/usr/src/app/pages/trading-basics.vue")
  },
  {
    name: "trading-basics___it",
    path: "/it/trading-basics",
    component: () => import("/usr/src/app/pages/trading-basics.vue")
  },
  {
    name: "trading-hours___en___default",
    path: "/trading-hours",
    component: () => import("/usr/src/app/pages/trading-hours.vue")
  },
  {
    name: "trading-hours___en",
    path: "/en/trading-hours",
    component: () => import("/usr/src/app/pages/trading-hours.vue")
  },
  {
    name: "trading-hours___es",
    path: "/es/trading-hours",
    component: () => import("/usr/src/app/pages/trading-hours.vue")
  },
  {
    name: "trading-hours___it",
    path: "/it/trading-hours",
    component: () => import("/usr/src/app/pages/trading-hours.vue")
  },
  {
    name: "trading-tools___en___default",
    path: "/trading-tools",
    component: () => import("/usr/src/app/pages/trading-tools.vue")
  },
  {
    name: "trading-tools___en",
    path: "/en/trading-tools",
    component: () => import("/usr/src/app/pages/trading-tools.vue")
  },
  {
    name: "trading-tools___es",
    path: "/es/trading-tools",
    component: () => import("/usr/src/app/pages/trading-tools.vue")
  },
  {
    name: "trading-tools___it",
    path: "/it/trading-tools",
    component: () => import("/usr/src/app/pages/trading-tools.vue")
  },
  {
    name: "webtrader___en___default",
    path: "/webtrader",
    component: () => import("/usr/src/app/pages/webtrader.vue")
  },
  {
    name: "webtrader___en",
    path: "/en/webtrader",
    component: () => import("/usr/src/app/pages/webtrader.vue")
  },
  {
    name: "webtrader___es",
    path: "/es/webtrader",
    component: () => import("/usr/src/app/pages/webtrader.vue")
  },
  {
    name: "webtrader___it",
    path: "/it/webtrader",
    component: () => import("/usr/src/app/pages/webtrader.vue")
  },
  {
    name: "why-us___en___default",
    path: "/why-us",
    component: () => import("/usr/src/app/pages/why-us.vue")
  },
  {
    name: "why-us___en",
    path: "/en/why-us",
    component: () => import("/usr/src/app/pages/why-us.vue")
  },
  {
    name: "why-us___es",
    path: "/es/why-us",
    component: () => import("/usr/src/app/pages/why-us.vue")
  },
  {
    name: "why-us___it",
    path: "/it/why-us",
    component: () => import("/usr/src/app/pages/why-us.vue")
  }
]