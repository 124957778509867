export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/png","sizes":"32x32","href":"/img/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/img/favicon-16x16.png"}],"style":[],"script":[{"children":"window.dataLayer = window.dataLayer || [];"},{"src":"//fw-cdn.com/2363716/2970411.js","chat":"true","async":true,"defer":true},{"innerHTML":"\n            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n            'https://www.googletagmanager.com/gtm.js?id=undefined'+dl;f.parentNode.insertBefore(j,f);\n            })(window,document,'script','dataLayer','undefined');\n          ","type":"text/javascript","charset":"utf-8"},{"async":true,"src":"https://www.googletagmanager.com/gtag/js?id=undefined"},{"innerHTML":"\n            window.dataLayer = window.dataLayer || [];\n            function gtag(){dataLayer.push(arguments);}\n            gtag('js', new Date());\n\n            gtag('config', 'undefined');\n            gtag('config', 'undefined');\n          ","type":"text/javascript","charset":"utf-8"}],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1.0","title":"24markets.com","__dangerouslyDisableSanitizersByTagID":{"gtm-id-1":["innerHTML"],"gtm-id-2":["innerHTML"],"gtag-config":["innerHTML"]}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false