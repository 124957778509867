import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_hxpx9TAykM4St_ybsPIAJmhOKQmae5Yd749dL71wvC8 from "/usr/src/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UA_8kj5D6fdTpFeJMbyCmMq31MBtfyeQDP4c1RUCtrM from "/usr/src/app/node_modules/nuxt-meta-pixel/dist/runtime/plugin.client.mjs";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_olPpMfdiml458rIYogF70mzbFJHGECK67clOiWhMSSE from "/usr/src/app/node_modules/nuxt-aos/dist/runtime/plugin.js";
import bootstrap_client_c_fMhHoCA_KTiu88qzHpLQCaAGpNyyqa3D_a3ozzERI from "/usr/src/app/plugins/bootstrap.client.ts";
import cookieconsent_client_ZKSq_CCUbaifKXgeByG4Oe4o0vmzCSM4u3BsV2H9fOg from "/usr/src/app/plugins/cookieconsent.client.ts";
import error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU from "/usr/src/app/plugins/error-handler.ts";
import imagehelper_73z5CHUOjVBpmBvz5e3q2GVye7_HGxRddvEurcGKjGY from "/usr/src/app/plugins/imagehelper.ts";
import markdownit_JeLYIMyGcGsBcQ_QoLxd88vGLe_Qep2nEh8cM8c11Wc from "/usr/src/app/plugins/markdownit.ts";
import vue_awesome_paginate_1ugRP_i_WyMnLvM6ccRrLO4O_pLjCkebP6e6z3vXRyw from "/usr/src/app/plugins/vue-awesome-paginate.ts";
import wow_client_V_V0zJxKWk595KZTjyIaummxjcAe7cfhfO4_pkq5lzc from "/usr/src/app/plugins/wow.client.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_hxpx9TAykM4St_ybsPIAJmhOKQmae5Yd749dL71wvC8,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_client_UA_8kj5D6fdTpFeJMbyCmMq31MBtfyeQDP4c1RUCtrM,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  plugin_olPpMfdiml458rIYogF70mzbFJHGECK67clOiWhMSSE,
  bootstrap_client_c_fMhHoCA_KTiu88qzHpLQCaAGpNyyqa3D_a3ozzERI,
  cookieconsent_client_ZKSq_CCUbaifKXgeByG4Oe4o0vmzCSM4u3BsV2H9fOg,
  error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU,
  imagehelper_73z5CHUOjVBpmBvz5e3q2GVye7_HGxRddvEurcGKjGY,
  markdownit_JeLYIMyGcGsBcQ_QoLxd88vGLe_Qep2nEh8cM8c11Wc,
  vue_awesome_paginate_1ugRP_i_WyMnLvM6ccRrLO4O_pLjCkebP6e6z3vXRyw,
  wow_client_V_V0zJxKWk595KZTjyIaummxjcAe7cfhfO4_pkq5lzc,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]