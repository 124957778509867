
// @ts-nocheck
import locale_en_46json_e2607e1d from "#nuxt-i18n/e2607e1d";
import locale_es_46json_e19585ec from "#nuxt-i18n/e19585ec";
import locale_it_46json_68477585 from "#nuxt-i18n/68477585";

export const localeCodes =  [
  "en",
  "es",
  "it"
]

export const localeLoaders = {
  en: [
    {
      key: "locale_en_46json_e2607e1d",
      load: () => Promise.resolve(locale_en_46json_e2607e1d),
      cache: true
    }
  ],
  es: [
    {
      key: "locale_es_46json_e19585ec",
      load: () => Promise.resolve(locale_es_46json_e19585ec),
      cache: true
    }
  ],
  it: [
    {
      key: "locale_it_46json_68477585",
      load: () => Promise.resolve(locale_it_46json_68477585),
      cache: true
    }
  ]
}

export const vueI18nConfigs = []

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    jit: true,
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      name: "English",
      code: "en",
      iso: "gb",
      files: [
        "/usr/src/app/i18n/locales/en.json"
      ]
    },
    {
      name: "Español",
      code: "es",
      iso: "es",
      files: [
        "/usr/src/app/i18n/locales/es.json"
      ]
    },
    {
      name: "Italiano",
      code: "it",
      iso: "it",
      files: [
        "/usr/src/app/i18n/locales/it.json"
      ]
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_and_default",
  lazy: false,
  langDir: "locales/",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: true,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "i18n_redirected",
    cookieSecure: false,
    fallbackLocale: "",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    name: "English",
    code: "en",
    iso: "gb",
    files: [
      {
        path: "/usr/src/app/i18n/locales/en.json",
        cache: undefined
      }
    ]
  },
  {
    name: "Español",
    code: "es",
    iso: "es",
    files: [
      {
        path: "/usr/src/app/i18n/locales/es.json",
        cache: undefined
      }
    ]
  },
  {
    name: "Italiano",
    code: "it",
    iso: "it",
    files: [
      {
        path: "/usr/src/app/i18n/locales/it.json",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/